import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  CardBody,
  Form,
  Card,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Tooltip,
} from "reactstrap";
import DateFilter from "views/examples/DateFilter";
import axios from "axios";
import { baseUrl } from "../../constants/url";
import authHeader from "services/auth-header";
import Select, { components } from "react-select";
import LineImage from "../../assets/img/icons/lines_icon.png";
import RecipeImage from "../../assets/img/icons/Recipe_New1.png";
import RangeSlider from "components/RangeSlider/RangeSlider";
import { DataContext } from "layouts/Admin";
import sortArray from "utils/sort";
import TimePickers from "views/examples/TimePickers";
import { FaInfoCircle } from "react-icons/fa";

const customStyles = {
  input: (base) => ({
    ...base,
    color: "white",
  }),
  singleValue: (base) => ({
    ...base,
    color: "white",
  }),
  placeholder: (base) => ({
    ...base,
    color: "white",
  }),
  control: (base, state) => ({
    ...base,
    // background: "#023950",
    // background: "#737977",
    background: "#19312e",
    // match with the menu
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    // borderColor: state.isFocused ? "yellow" : "green",
    borderColor: state.isFocused ? "#a7ce39" : "#8898aa",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#a7ce39" : "#a7ce39",
    },
  }),
  menu: (base) => ({
    ...base,
    zIndex: 2000,
    // background: "#737977",
    background: "#19312e",
    color: "white",
    // override border radius to match the box
    border: "1px solid #8898aa",
    // border: '1px solid #a7ce39',
    borderRadius: 5,
    // kill the gap
    marginTop: 0,
  }),
  menuList: (base, state) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
    // "::-webkit-scrollbar": {
    //   display: "none",
    // },
    // overflow: 'hidden'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      // backgroundColor: isFocused ? "#2462ad" : null,
      backgroundColor: isFocused ? "#a7ce39" : null,
      color: isFocused ? "black" : "white",
      // fontWeight: 'bold'
      // color: "#333333",
    };
  },
};

const DropdownIndicator = (props, image) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={image} width={25} alt="" />
    </components.DropdownIndicator>
  );
};

function Filters({
  handleApplyFilters,
  filtersData,
  clientId,
  clientSubscription,
  timeRange,
  setTimeRange,
}) {
  const [filterState, setFilterState] = useState({
    ...filtersData,
  });
  const [lineData, setLineData] = useState("Loading...");
  const { lineData: linesDataResponse } = useContext(DataContext);
  const [recipeData, setRecipeData] = useState("Loading...");
  const [initialLoading, setInitialLoading] = useState(true);
  const [tooltip, setTooltip] = useState(false);

  useEffect(() => {
    setFilterState({ ...filtersData });
  }, [filtersData]);

  useEffect(() => {
    if (linesDataResponse && linesDataResponse !== "Loading...") {
      if (linesDataResponse === "No Data Found")
        return setLineData(linesDataResponse);
      const option = linesDataResponse.map((item) => {
        return {
          label: item,
          value: item,
        };
      });
      option.unshift({ label: "All", value: "All" });
      setLineData(option);
    }
  }, [linesDataResponse]);

  useEffect(() => {
    // axios
    //   .get(`${baseUrl}/api/get_lookup_data/line/${clientId}`, {
    //     headers: authHeader(),
    //   })
    //   .then((res) => {
    //     const option = res.data.data.map((item) => {
    //       return {
    //         label: item,
    //         value: item,
    //       };
    //     });
    //     option.unshift({label: 'All', value: 'All'})
    //     setLineData(option);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     setLineData("No Data Found")
    //   });
    axios
      .get(`${baseUrl}/api/get_lookup_data/recipe/${clientId}`, {
        headers: authHeader(),
      })
      .then((res) => {
        console.log("response");
        let sortedData = sortArray(res.data.data);

        const option = sortedData.map((item) => {
          return {
            label: item,
            value: item,
          };
        });
        option.unshift({ label: "All", value: "All" });
        setRecipeData(option);
      })
      .catch((err) => {
        console.log(err);
        setRecipeData("No Data Found");
      });
  }, [clientId]);

  useEffect(() => {
    if (
      filterState.line !== "Loading..." &&
      filterState.recipe !== "Loading..."
    )
      setInitialLoading(false);
  }, [filterState.line, filterState.recipe]);

  useEffect(() => {
    if (
      filterState.line !== "Loading..." &&
      filterState.recipe !== "Loading..."
    )
      handleApplyFilters(filterState);
  }, [filterState.line, filterState.recipe]);

  const onChangeRange = (e, value, name) => {
    setFilterState((prevState) => {
      if (name === "kpiRange") {
        return {
          ...prevState,
          kpiMin: value[0],
          kpiMax: value[1],
        };
      }
    });
  };

  const onChangeSelectValue = (value, name) => {
    console.log(name, value);
    setFilterState((prevState) => {
      return {
        ...prevState,
        [name.name]: value.value,
        searchByRecipe: "",
      };
    });
  };

  const handleDateRange = (start_date, end_date) => {
    setFilterState((prevState) => {
      return {
        ...prevState,
        start_date,
        end_date,
      };
    });
  };

  const onChangeText = (e) => {
    setFilterState((prevState) => {
      return {
        ...prevState,
        searchByRecipe: e.target.value,
      };
    });
  };

  const handleApplyFromDateFilter = () => {
    console.log("apply from date filter");
    handleApplyFilters(filterState);
  };

  const handleApply = () => {
    console.log("apply");
    handleApplyFilters(filterState);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleApplyFilters(filterState);
    }
  };

  return (
    <Container fluid>
      <Card>
        <CardBody className="rounded px-4 py-3">
          <Form>
            <div className="">
              <Row>
                <Col xs={12} md={6} xl={4} xxl={2}>
                  <DateFilter
                    filtersData={filtersData}
                    handleDateRange={handleDateRange}
                    handleApplyFromDateFilter={handleApplyFromDateFilter}
                  />
                </Col>

                <Col xs={12} md={6} xl={4} xxl={2}>
                  <FormGroup className="mb-2">
                    <label className="form-control-label textWhite d-block filter-label-font-size">
                      Line
                    </label>
                    {lineData === "Loading..." ? (
                      lineData
                    ) : lineData === "No Data Found" ? (
                      lineData
                    ) : (
                      <Select
                        placeholder="Select Line..."
                        components={{
                          DropdownIndicator: (props) =>
                            DropdownIndicator(props, LineImage),
                        }}
                        name="line"
                        value={{ label: filterState.line }}
                        onChange={onChangeSelectValue}
                        styles={customStyles}
                        options={lineData}
                      />
                    )}
                  </FormGroup>
                </Col>
                <Col xs={12} md={6} xl={4} xxl={2}>
                  <FormGroup className="mb-2">
                    <label className="form-control-label textWhite d-block filter-label-font-size">
                      Recipe
                    </label>
                    {recipeData === "Loading..." ? (
                      recipeData
                    ) : recipeData === "No Data Found" ? (
                      recipeData
                    ) : (
                      <Select
                        placeholder="Select Recipe..."
                        components={{
                          DropdownIndicator: (props) =>
                            DropdownIndicator(props, RecipeImage),
                        }}
                        name="recipe"
                        value={{ label: filterState.recipe }}
                        onChange={onChangeSelectValue}
                        styles={customStyles}
                        options={recipeData}
                      />
                    )}
                  </FormGroup>
                </Col>
                {clientSubscription === "premium" && (
                  <Col xs={12} md={6} xl={4} xxl={2} className="mb-3">
                    <TimePickers
                      filtersData={filtersData}
                      handleDateRange={handleDateRange}
                      timeRange={timeRange}
                      setTimeRange={setTimeRange}
                    />
                  </Col>
                )}
                <Col xs={12} md={6} xl={4} xxl={2}>
                  <FormGroup className="mb-2">
                    <label className="form-control-label textWhite d-block filter-label-font-size">
                      Search By Recipe
                      <FaInfoCircle
                        id="search-by-recipe-tooltip"
                        fontSize={"18px"}
                        className="ml-2"
                      />
                    </label>
                    <Tooltip
                      placement={"top"}
                      isOpen={tooltip}
                      target={`search-by-recipe-tooltip`}
                      toggle={() => {
                        setTooltip((st) => !st);
                      }}
                    >
                      Search by Recipe: Enter the initials or part of a recipe
                      name to find matching recipes. This search will fetch all
                      results that include the provided text in the recipe name.
                    </Tooltip>
                    <Input
                      value={filterState.searchByRecipe}
                      onChange={onChangeText}
                      name="searchByRecipe"
                      onKeyDown={handleKeyDown}
                      styles={customStyles}
                      className="textBg"
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6} xl={4} xxl={2}>
                  <FormGroup className="mb-2">
                    <label className="form-control-label textWhite d-block filter-label-font-size">
                      KPI (%)
                    </label>
                    <RangeSlider
                      value={[filterState.kpiMin, filterState.kpiMax]}
                      min={0}
                      max={1000}
                      onChange={onChangeRange}
                      name="kpiRange"
                    />
                  </FormGroup>
                </Col>
                <Col
                  className="justify-content-end align-items-center d-flex pt-3 mb-2"
                  xs={12}
                >
                  <Button
                    style={{ backgroundColor: "#a7ce39", border: "none" }}
                    onClick={handleApply}
                  >
                    Apply
                  </Button>
                  <Button
                    className=""
                    color="secondary"
                    onClick={() => {
                      const today = new Date();
                      const yesterday = new Date(today);
                      yesterday.setDate(yesterday.getDate() - 7);
                      setFilterState({
                        start_date: yesterday.toISOString(),
                        end_date: today.toISOString(),
                        recipe: "All",
                        line: "All",
                        kpiMin: 0,
                        kpiMax: 200,
                        searchByRecipe: "",
                      });
                      handleApplyFilters({
                        start_date: yesterday.toISOString(),
                        end_date: today.toISOString(),
                        recipe: "All",
                        line: "All",
                        kpiMin: 0,
                        kpiMax: 200,
                        searchByRecipe: "",
                      });
                    }}
                  >
                    Clear
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Container>
  );
}

export default Filters;
